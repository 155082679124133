<template>
  <div>
    <el-menu class="sidebar" :default-active="defaultActive" @select="selectMune" :router="true">
      <el-menu-item v-for="(its, idx) in menus" :key="idx"
        :index="its.route">{{ its.label }}</el-menu-item>
    </el-menu>

  </div>
</template>

<script>
// import { getUserInfo } from "@/utils/auth";
// import { roles } from '@/biz/static-data'
export default {
  data() {
    return {
      roleName: '',
      shopManager: '',
      roleSuperAdmin: '',
      defaultActive: ''
    }
  },
  watch: {
    $route(to){
      this.defaultActive = to.meta.path
    }
  },
  created(){
    //  this.roleName = getUserInfo('userInfo').roleName
    //  this.shopManager= roles.shopManager
    //  this.roleSuperAdmin= roles.superAdmin
  },
  computed: {
    adminUserInfo: function() {
      return this.$store.getters.adminUserInfo
    },
    menus: function() {
      let {position, eateryId, eateryGroupId} = this.adminUserInfo,
      menu = []
      console.log(eateryId)
      if(position == 1) menu = [
        {route: '/eatery', label: 'Eatery'},
        {route: '/eateryGroup', label: 'Eatery Group'},
        {route: '/appMembers', label: 'App Members'},
        {route: '/stampTransactions', label: 'Stamp Transactions'},
        {route: '/GTransactions', label: 'G$ Transactions'},
        {route: '/notice', label: 'Notice'},
        {route: '/settings', label: 'Settings'},
        {route: '/campaign', label: 'Campaign'},
      ]
      else if(position == 2) menu = [
        {route: '/eatery', label: 'Eatery'},
        {route: `/eateryGroup/edit/${eateryGroupId}`, label: 'Eatery Group'},
        {route: '/stampTransactions', label: 'Stamp Transactions'},
      ]
      else if(position == 3) menu = [
        {route: `/eatery/edit/${eateryId}`, label: 'Eatery'},
        {route: '/stampTransactions', label: 'Stamp Transactions'},
      ]

      return menu;
    }
  },
  methods: {
    selectMune(to) {
      this.$router.push(to)
      // console.log(form)
    },
    getDefaultActive() {
      const path = this.$route.meta.path
      return path
    },
  },
  mounted() {
    this.defaultActive = this.getDefaultActive()
    // console.log(this.defaultActive)
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  text-align: left;
  font-size: 26px;
  font-weight: bold;
  width: 300px;
  margin-left: 40px;
  background-color: #F0F0EF;
  border:0;
}
.el-menu-item{
  background-color: #E5F9FF;
  margin-bottom: 2px;
  color: #000;
  font-weight: 400;
  font-size: 22px;
  line-height: 2rem;
  font-family: Roboto;
  padding:9px 0 9px 30px;
}
.el-menu-item:nth-child(1){
   margin-top: 40px;
   border-radius: 20px 20px 0 0;
}
.el-menu-item:nth-last-child(1){
  border-radius: 0 0 20px 20px;
}

.is-active {
  background-color: #56DBE4;
  font-weight: 700;
  font-family: Inter;
}
</style>
