<template>
  <div class="container">
    <el-container>
      <el-header height="auto"><Header></Header></el-header>
      <el-container>
        <el-aside width="auto"><Menu></Menu></el-aside>
        <el-main><router-view class="router-view"/></el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Menu from "./Menu";
import Header from "./Header";

export default {
  components: { Menu, Header },
  data() {
    return {
      
    }
  },
  computed: {

  },
};
</script>
<style scoped>
.container{
  display: flex;
  height: 100% !important;
}
.el-header {
  padding: 0;
}
.el-main {
  padding: 40px;
}
.router-view {
  padding:60px;
  background:#fff;
  border-radius: 30px;
  /* height:calc(100vh - 140px); */
  overflow:auto;
}
</style>