<template>
  <div class="header">
    <div style="font-size:1.375rem; font-weight:700;">Plastic Free Admin System (EPD)</div>
    <div style="display: flex;align-items:center">
      <div>EPD User({{ adminUserInfo.positionName }})</div>
      <div class="text-cursor" @click="logout">
        Log out 
        <img src="@/assets/images/exit.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import { logout } from "@/api/security";
import { removeToken, removeAll, getUserInfo } from "@/utils/auth";
export default {
  computed: {
    adminUserInfo: function() {
      return this.$store.getters.adminUserInfo
    }
  },
  methods: {
    logout() {
      let d = {
        refreshToken :getUserInfo('refreshToken')
      }
      logout(d).then(() => {
          removeToken();
          removeAll();
          this.$store.dispatch("setAdminUserInfo", null);
          location.reload();
        })
        .catch((error) => {
          if (error.response) {
            this.$message.error(error.response.data.error_message);
          }
        })
    },
  },
  mounted() {
    // console.log(this['adminUserInfo'])
  }
};
</script>

<style lang="scss"  scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.75rem;
  padding-left: 2.5rem;
  background: #1B7379;
  color: #fff;
  font-size:1.125rem;
  line-height: 2rem;
}
.logo {
  display: flex;
  align-items: center;
}
.logo-img {
  width: 40px;
  height: 40px;
}
.logo-text {
  font-size: 20px;
  font-weight: bold;
}
.userinfo {
  display: flex;
  font-size: 20px;
  align-items: center;
}
.userinfo-button,
.userinfo-divider,
.userinfo-text {
  margin: 0 20px;
}
.userinfo-button {
  border: 3px solid #fff;
}
.userinfo-cursor {
  cursor: pointer;
}
.text-cursor {
  margin-left:3.75rem;
  padding:0.875rem 2.5rem 0.875rem 3.75rem;
  background:#BAEA32;
  color:#000000;
  display: flex;
  align-items: center;

  img {
    width: 1.125rem;
    height: auto;
    margin-left: 1.25rem;
  }
}
</style>
